import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useToastContext } from "@shared/context/ToastContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Icon from "@ats/src/components/shared/Icon";
import Tooltip from "@ats/src/components/shared/Tooltip";

interface ICopyFieldProps {
  value: string;
  label: string;
  isLink?: boolean;
}

function CopyField({ value, label = "Copy", isLink = false }: ICopyFieldProps) {
  const addToast = useToastContext();

  return (
    <Styled.CopyField>
      {isLink ? (
        <a href={value} target="_blank" rel="noreferrer">
          {value}
        </a>
      ) : (
        <span>{value}</span>
      )}
      <Tooltip label={label}>
        <CopyToClipboard text={value} onCopy={() => addToast({ title: "Copied", kind: "success" })}>
          <Styled.CopyLink>
            <Icon name="copy" />
          </Styled.CopyLink>
        </CopyToClipboard>
      </Tooltip>
    </Styled.CopyField>
  );
}

export default CopyField;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.CopyField = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CopyField;
    ${[t.mb(5), t.pl(2), t.rounded.sm, t.text.base]}
    border: 1px solid ${t.dark ? "rgba(255,255,255,0.08)" : t.color.gray[300]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.gray[100]};
    color: ${t.dark ? t.color.gray[200] : t.color.black};
    display: flex;
    justify-content: space-between;
    align-items: top;
    overflow: hidden;

    > div {
      height: 100%;
    }

    span,
    a {
      ${[t.py(2)]}
      white-space: pre;
    }

    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    a:hover {
      text-decoration: underline;
    }

    ${t.mq["md"]} {
      ${[t.text.sm]}
    }
  `;
});

Styled.CopyLink = styled.a((props) => {
  const t: any = props.theme;
  return css`
    label: CopyField_CopyLink;
    ${[t.w(8), t.h(8), t.ml(1), t.rounded.sm]}
    top: -8px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &:hover {
      background-color: ${t.dark ? t.color.gray[700] : t.color.gray[200]};
      color: ${t.dark ? t.color.white : t.color.black};
    }
  `;
});
