import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import isEmpty from "lodash/isEmpty";

import Icon from "@ats/src/components/shared/Icon";
import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import Button from "@ats/src/components/shared/Button";
import FormLabel from "@ats/src/components/forms/FormLabel";
import FormConditionalFields from "@ats/src/components/forms/FormConditionalFields";
import CopyField from "@ats/src/components/shared/CopyField";

import { useCareersPageHerokuDomainInfo } from "@shared/queryHooks/useCareersPage";
import { useQueryClient } from "react-query";
import { useToastContext } from "@shared/context/ToastContext";

function CustomDomainStatus({ careersPage }) {
  const addToast = useToastContext();
  // const [copied, setCopied] = React.useState(false);
  const queryClient = useQueryClient();
  const {
    data: careersPageHerokuDomainInfoData,
    isLoading: isLoadingCareersPageHerokuDomainInfo,
    isFetching: isFetchingCareersPageHerokuDomainInfo,
    isError: isErrorCareersPageHerokuDomainInfo,
    error: careersPageHerokuDomainInfoError,
  } = useCareersPageHerokuDomainInfo(careersPage?.id);

  window.logger("%c[CustomDomainStatus] RENDER", "background-color: #e0d038", {
    careersPageHerokuDomainInfoData,
    isErrorCareersPageHerokuDomainInfo,
    careersPageHerokuDomainInfoError,
    isLoadingCareersPageHerokuDomainInfo,
    isFetchingCareersPageHerokuDomainInfo,
  });

  const handleClickOnRefreshDomain = (event) => {
    event.preventDefault();

    queryClient.refetchQueries("careersPageHerokuDomainInfo").then(() => {
      addToast({ title: "Refresh successful", kind: "success" });
      queryClient.refetchQueries("currentOrganization");
    });
  };

  if (isLoadingCareersPageHerokuDomainInfo) {
    return <LoadingIndicator subtle />;
  }

  if (isErrorCareersPageHerokuDomainInfo && !isEmpty(careersPage.customDomain)) {
    return (
      <FormConditionalFields>
        <Styled.Value>
          <span>Unable to load domain, please try refreshing.</span>
        </Styled.Value>
        <Button
          styleType="secondary"
          onClick={handleClickOnRefreshDomain}
          loading={isFetchingCareersPageHerokuDomainInfo}
        >
          Refresh
        </Button>
      </FormConditionalFields>
    );
  }

  if (
    careersPageHerokuDomainInfoData?.status == undefined &&
    careersPageHerokuDomainInfoData?.acmStatus == undefined
  ) {
    return null;
  }

  return (
    <>
      <FormLabel label="CNAME" />
      <Styled.Description>
        Supply this to your DNS provider for the destination of CNAME or ALIAS records.
      </Styled.Description>
      <CopyField value={careersPageHerokuDomainInfoData.cname} label="Copy" />

      <FormConditionalFields>
        {/* status: failing, pending, succeeded */}
        {/* acmStatus: failing, pending,
        dns-verified, cert issued <--- These 2 don't have an acmStatusReason, "cert issued" is when we know it should be working
        */}

        {["pending", "failing"].includes(careersPageHerokuDomainInfoData.status) ? (
          <>
            <FormLabel label="Custom domain status" />
            <Styled.Value>
              <span>{careersPageHerokuDomainInfoData.status}</span>
            </Styled.Value>
          </>
        ) : careersPageHerokuDomainInfoData.acmStatus == undefined ? (
          <>
            <FormLabel label="Custom domain status" />
            <Styled.Value>
              <span>
                Checking your domain's DNS records, refresh once you have updated them with the
                above CNAME destination.
              </span>
            </Styled.Value>
          </>
        ) : null}

        {careersPageHerokuDomainInfoData.acmStatus != undefined ? (
          <>
            <FormLabel label="SSL certificate status" />
            <Styled.Value>
              {careersPageHerokuDomainInfoData.acmStatus === "cert issued" && (
                <Icon name="check-circle" />
              )}
              <span>{careersPageHerokuDomainInfoData.acmStatus}</span>
            </Styled.Value>

            {careersPageHerokuDomainInfoData.acmStatusReason && (
              <>
                <FormLabel label="Certificate status reason" />
                <Styled.Value>
                  <span>{careersPageHerokuDomainInfoData.acmStatusReason}</span>
                </Styled.Value>
              </>
            )}
          </>
        ) : null}

        <Button
          styleType="secondary"
          onClick={handleClickOnRefreshDomain}
          loading={isFetchingCareersPageHerokuDomainInfo}
        >
          Refresh
        </Button>
      </FormConditionalFields>
    </>
  );
}

CustomDomainStatus.defaultProps = {};

export default CustomDomainStatus;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Value = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: CustomDomainStatus_Value;
    ${[t.mb(5)]}
    line-height: 1.6;
    display: flex;
    align-items: center;
    color: ${t.dark ? t.color.gray[300] : t.color.black};

    svg {
      ${t.mr(2)}
    }

    span:first-letter {
      text-transform: capitalize;
    }
  `;
});

Styled.Description = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: CustomDomainStatus_Description;
    ${[t.text.xs, t.mt(-1), t.mb(2)]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    max-width: 32rem;
  `;
});
