import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPut } from "@shared/queryHooks/api";

/* API requests
--===================================================-- */
const updateCareersPage = async ({ id, ...careersPage }) => {
  window.logger("%c[useCareersPage] updateCareersPage\n\n\n\n", "background-color: #FF76D2", {
    careersPage,
  });
  return await apiPut({
    path: `/careers_pages/${id}`,
    variables: { careersPage },
  });
};

const getCareersPageHerokuDomainInfo = async (careersPageId) => {
  window.logger("%c[useCareersPage] getCareersPageHerokuDomainInfo", "background: #19d219", {
    careersPageId,
  });
  if (careersPageId != undefined) {
    return await apiGet({ path: `/careers_pages/${careersPageId}/heroku_domain` });
  }
};

/* Hooks
--===================================================-- */
function useUpdateCareersPage() {
  const queryClient = useQueryClient();
  return useMutation(updateCareersPage, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useCareersPage] useUpdateCareersPage onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries(["careersPage", data.id]);
      queryClient.invalidateQueries(["currentOrganization", data.organizationId]);
      queryClient.invalidateQueries("me");
      queryClient.refetchQueries(["careersPageHerokuDomainInfo", data.id]);
      return data;
    },
    // throwOnError: true,
  });
}

function useCareersPageHerokuDomainInfo(
  careersPageId,
): {
  status: any;
  data: any;
  error: any;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
} {
  return useQuery(
    ["careersPageHerokuDomainInfo", careersPageId],
    () => getCareersPageHerokuDomainInfo(careersPageId),
    {
      refetchOnWindowFocus: false,
      retry: 2, // 3, retries 3 times
    },
  );
}

export { useUpdateCareersPage, useCareersPageHerokuDomainInfo };
